import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, Flex } from "@chakra-ui/react";

const PageLayout = ({ children }) => {
  return (
    <Flex
      fontFamily="Sailec"
      overflow="hidden"
      w="full"
      flexDir="column"
      bg="#fff"
      minH="100vh"
    >
      <Header />
      <Flex justifyContent="center" align="center">
        <Box
          mt={{ base: "80px", lg: "90px" }}
          w={{ base: "full", lg: "1390px" }}
          px="20px"
        >
          {children}

          <Footer />
        </Box>
      </Flex>
    </Flex>
  );
};

export default PageLayout;
