import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Flex
      className="full_width"
      bg="#090c02"
      py={{ base: "60px", md: "unset" }}
      h={{ base: "unset", md: "230px" }}
      color="#fff"
      justifyContent="center"
      align="center"
      px="20px"
    >
      <Box w={{ base: "100%", lg: "1350px" }}>
        <Flex
          align="center"
          justifyContent="space-between"
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: "24px", md: "unset" }}
        >
          <Image src="/assets/ezpark-light.svg" w="150px" h="24px" />

          <Flex
            align="center"
            gap="24px"
            flexDir={{ base: "column", md: "row" }}
          >
            {[
              "Terms and Conditions",
              "Frequently Asked Questions",
              "Careers",
            ].map((item, i) => (
              <Text fontSize={{ base: "12px", md: "15px" }} key={i}>
                {item}
              </Text>
            ))}
          </Flex>
        </Flex>

        <Flex justifyContent="center" w="full" my="40px">
          <Flex justifyContent="center" w="50%" bg="#545658" h="1px" />
        </Flex>

        <Text
          textAlign="center"
          color="#646668"
          fontSize={{ base: "12px", md: "14px" }}
          lineHeight={{ base: "12px", md: "14px" }}
        >
          ©{year} EZPark Limited. All rights reserved
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
