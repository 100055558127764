export const BusinessIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M12 14.25C8.83955 14.2582 5.73363 13.4267 3 11.8406V19.5C3 19.6989 3.07902 19.8896 3.21967 20.0303C3.36032 20.1709 3.55109 20.25 3.75 20.25H20.25C20.4489 20.25 20.6397 20.1709 20.7803 20.0303C20.921 19.8896 21 19.6989 21 19.5V11.8406C18.2646 13.4225 15.1599 14.2537 12 14.25Z"
        fill={fill}
      />
      <path
        d="M20.25 6.75H3.75C3.33579 6.75 3 7.08579 3 7.5V19.5C3 19.9142 3.33579 20.25 3.75 20.25H20.25C20.6642 20.25 21 19.9142 21 19.5V7.5C21 7.08579 20.6642 6.75 20.25 6.75Z"
        stroke={fill}
        strokeWidth="1.2"
      />
      <path
        d="M15.75 6.75V5.25C15.75 4.85218 15.592 4.47064 15.3107 4.18934C15.0294 3.90804 14.6478 3.75 14.25 3.75H9.75C9.35218 3.75 8.97064 3.90804 8.68934 4.18934C8.40804 4.47064 8.25 4.85218 8.25 5.25V6.75"
        stroke={fill}
        strokeWidth="1.2"
      />
      <path
        d="M21 11.8406C18.2646 13.4225 15.1599 14.2537 12 14.25C8.83955 14.2582 5.73363 13.4267 3 11.8406"
        stroke={fill}
        strokeWidth="1.2"
      />
      <path d="M10.875 11.25H13.125" stroke={fill} strokeWidth="1.2" />
    </svg>
  );
};

export const CustomerIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 19C18 16.7909 15.3137 15 12 15C8.68629 15 6 16.7909 6 19M12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12Z"
        stroke={fill}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export const OperatorIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.40002 13.6999H3.23641M3.23641 13.6999H15.9636M3.23641 13.6999C3.24531 13.6587 3.25584 13.6179 3.26799 13.5776C3.297 13.4814 3.33796 13.3888 3.42034 13.2035L4.65735 10.4202C4.90192 9.86993 5.02441 9.59462 5.2177 9.39287C5.38856 9.21453 5.59813 9.07831 5.83049 8.9946C6.09336 8.8999 6.39471 8.8999 6.9969 8.8999H12.2029C12.8051 8.8999 13.1067 8.8999 13.3696 8.9946C13.6019 9.07831 13.8112 9.21453 13.9821 9.39287C14.1752 9.59449 14.2975 9.86957 14.5417 10.4191L15.7837 13.2136C15.8631 13.3922 15.9035 13.4831 15.932 13.5776C15.9441 13.6179 15.9547 13.6587 15.9636 13.6999M3.23641 13.6999C3.22644 13.746 3.2185 13.7926 3.2126 13.8394C3.20002 13.9391 3.20002 14.0404 3.20002 14.2433V18.4999M15.9636 13.6999H16.8M15.9636 13.6999C15.9736 13.746 15.9816 13.7926 15.9875 13.8394C16 13.9385 16 14.0392 16 14.2396V18.5M16 18.5L12.8 18.5M16 18.5V19.2998C16 20.1835 15.2837 20.8999 14.4 20.8999C13.5164 20.8999 12.8 20.1836 12.8 19.2999V18.5M12.8 18.5L6.40002 18.4999M6.40002 18.4999H3.20002M6.40002 18.4999V19.2999C6.40002 20.1836 5.68368 20.8999 4.80002 20.8999C3.91637 20.8999 3.20002 20.1836 3.20002 19.2999V18.4999"
        stroke={fill}
        strokeWidth="1.2"
      />
      <path
        d="M19.8 20.4001V5.7914M19.8 5.7914H19C18.4477 5.7914 18 5.34369 18 4.7914V4.6001C18 4.04781 18.4477 3.6001 19 3.6001H20.6C21.1523 3.6001 21.6 4.04781 21.6 4.6001V4.7914C21.6 5.34369 21.1523 5.7914 20.6 5.7914H19.8Z"
        stroke={fill}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export const ArrowIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
    >
      <path
        d="M3 6H13M13 6L10.2881 3M13 6L10.2881 9"
        stroke={fill}
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export const services = [
  {
    name: "Valet Parking",
    body: "Our professional valet services add a touch of luxury and convenience to any event or establishment. Experienced attendants ensure your guests' vehicles are parked securely and efficiently, providing a seamless experience from arrival to departure.",
  },
  {
    name: "Parking Facility Management",
    body: "EZPark offers comprehensive solutions for managing your parking facilities. From operations to maintenance, we handle everything to ensure a smooth, hassle-free experience for your customers. Our advanced security systems and regular upkeep guarantee the highest standards of service.",
  },
  {
    name: "Event Parking Management",
    body: "Ensure your event runs smoothly with our expert event parking management services. We provide tailored solutions to manage parking logistics, allowing your guests to arrive and depart without stress. Our team works closely with event planners to create custom parking plans for every occasion.",
  },
];

export const loginAs = [
  {
    id: 1,
    title: "Customer",
    img: <CustomerIcon fill="#444648" />,
    hover: <CustomerIcon fill={"#EE383A"} />,
    path: "https://app.parkinspace.ng/customer/auth/login",
  },
  {
    id: 2,
    title: "Business",
    img: <BusinessIcon fill="#444648" />,
    hover: <BusinessIcon fill={"#EE383A"} />,
    path: "https://app.parkinspace.ng/client/auth/login",
  },
  {
    id: 3,
    title: "Operator",
    img: <OperatorIcon fill="#444648" />,
    hover: <OperatorIcon fill={"#EE383A"} />,
    path: "https://app.parkinspace.ng/operator/auth/login",
  },
];

export const headers = ["Home", "About", "Services", "ParkinSpace", "Contact"];
export const socials = [
  { name: "fb", link: "https://web.facebook.com/EZParkLimited/?_rdc=1&_rdr" },
  { name: "ig", link: "https://www.instagram.com/ezparklimited/" },
  { name: "x", link: "https://twitter.com/EZParkLimited" },
  { name: "linkedin", link: "https://www.linkedin.com/company/ezpark-limited" },
];
