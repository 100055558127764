import React from "react";
import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { scroller } from "react-scroll";
import { headers, socials } from "../common/constants";
import { MdMenu } from "react-icons/md";

const Header = () => {
  const scrollToSection = (sectionId, retryCount = 5) => {
    const element = document.getElementById(sectionId);

    if (element) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -150,
      });
    } else if (retryCount > 0) {
      setTimeout(() => scrollToSection(sectionId, retryCount - 1), 500);
    } else {
      console.warn(`Element with ID ${sectionId} not found.`);
    }
  };

  const [isMobile] = useMediaQuery("(max-width: 991px)");

  return (
    <Flex
      flexDir="column"
      bg="#000"
      h="100px"
      w="full"
      justifyContent="center"
      align="center"
      pos="fixed"
      top="0"
      zIndex={100}
    >
      <Box w={{ base: "full", md: "1350px" }} px="20px">
        <Flex align="center" justifyContent="space-between">
          <Image
            src="/assets/ezpark-light.svg"
            w={{ base: "unset", md: "150px" }}
            h="24px"
          />

          {isMobile && <MdMenu color="#fff" size="20px" />}

          <Flex display={{ base: "none", md: "flex" }}>
            <Flex align="center" gap="32px">
              {headers.map((item, i) => (
                <Text
                  key={i}
                  _hover={{ textDecor: "underline" }}
                  onClick={() => scrollToSection(item?.toLowerCase())}
                  cursor="pointer"
                  fontWeight={500}
                  color="#fff"
                  fontSize="15px"
                >
                  {item}
                </Text>
              ))}
            </Flex>
          </Flex>

          <Flex display={{ base: "none", md: "flex" }}>
            <Flex align="center" gap="20px">
              {socials.map((item, i) => (
                <a key={i} href={item?.link} target="_blank" rel="noreferrer">
                  <Image
                    key={i}
                    src={`/assets/${item?.name}.svg`}
                    w="24px"
                    h="24px"
                    cursor="pointer"
                    fontWeight={500}
                  />
                </a>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
